import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Subpage from '../components/Subpage';
import isSubpage from '../utils/isSubpage';
import styles from './404.module.scss';

const NotFoundPage = ({ location }) => (
  <Layout isSubpage={isSubpage(location.pathname)}>
    <Seo title="404: Not found" />
    <Subpage className={styles.errorPage} title="Not found">
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Subpage>
  </Layout>
);

export default NotFoundPage;
